import { useContext } from 'react';
import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { UserUpdateRequest, UserSingleRequest } from 'src/actions';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import MessageContext from 'src/context/MessageContext';
import TopBarForm from 'src/components/shared/TopBarForm';
import { adminInfoProps } from 'src/types/adminTypes';
import { CompanyUserForm } from 'src/components/admin/_dashboard/companyUser';

export default function EditCompanyUser() {

    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const { userid }: any = useParams<any>();
    const submitForm = async (values: adminInfoProps, setSubmitting: any) => {
        setTimeout(() => dispatch(UserUpdateRequest(userid, values, setMessageHandler, setSubmitting)), 200);
    }
    const { user } = useSelector(state => state.user);
    var initialValues: adminInfoProps = user;

    useEffect(() => {
        dispatch(UserSingleRequest(userid));
    }, [userid])

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Edit User" link="/admin/user" />
            <CompanyUserForm submitForm={submitForm} initialValues={initialValues} />
        </Container>
    );
}
