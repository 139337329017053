import { styled } from '@mui/material/styles';
import { Card, Stack, Grid, TextField, IconButton, InputAdornment } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup'
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { adminInfoProps } from 'src/types/adminTypes';
import { makeid } from 'src/utils/common';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { useDispatch } from 'react-redux';
import { SupplierAllRequestData } from 'src/actions/supplierActions';
import { Supplier } from 'src/types/supplierTypes';
import { CompanyAllRequestData } from 'src/actions/companyActions';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface SubmitFormValues {
    initialValues: adminInfoProps,
    submitForm: any,
}

export default function CompanyUserForm({ initialValues, submitForm }: SubmitFormValues) {
  
    const dispatch = useDispatch();
    const SchemaUserForm = Yup.object().shape({
        firstName: Yup.string().min(4).trim().required('First name  is required'),
        lastName: Yup.string().trim().required('Last name is required'),
        email: Yup.string().max(255).required('Email is required'),
        supplierId:Yup.string().trim().when("role", {
            is: 'SUPPLIER',
            then: Yup.string().required("Supplier is required")
        }),
        companyId: Yup.string().required('Company is required'),
        role: Yup.string().trim().required('Role is required')
    });
    const navigate = useNavigate();
    const { redirectTo } = useSelector(state => state.user);
    const { suppliers } = useSelector(state => state.supplier);
    const { companies } = useSelector(state => state.company);

    useEffect(() => {
        if (redirectTo) navigate('/admin/company/user', { replace: true })
    }, [redirectTo])

    useEffect(() => {
        dispatch(SupplierAllRequestData())
        dispatch(CompanyAllRequestData())
    }, [])


    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    if(!values.password) delete values.password;
                    if(values.role !== 'SUPPLIER') delete values.supplierId;
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaUserForm}
                initialValues={{
                    firstName: initialValues?.firstName || '',
                    lastName: initialValues?.lastName || '',
                    email: initialValues?.email || '',
                    phoneNumber: initialValues?.phoneNumber || '',
                    supplierId:initialValues?.supplierId || '',
                    companyId: initialValues?.companyId || '',
                    role: initialValues?.role || '',
                    password: initialValues?.password || '',
                }}
            >
                {(formikProps: FormikProps<adminInfoProps>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.firstName && formikProps.errors.firstName)}
                                        fullWidth
                                        helperText={formikProps.touched.firstName && formikProps.errors.firstName}
                                        label="First Name"
                                        variant="standard"
                                        {...formikProps.getFieldProps('firstName')}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.lastName && formikProps.errors.lastName)}
                                        fullWidth
                                        helperText={formikProps.touched.lastName && formikProps.errors.lastName}
                                        label="Last Name"
                                        variant="standard"
                                        {...formikProps.getFieldProps('lastName')}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.email && formikProps.errors.email)}
                                        fullWidth
                                        {...formikProps.getFieldProps('email')}
                                        helperText={formikProps.touched.email && formikProps.errors.email}
                                        label="Email"
                                        variant="standard"
                                    />
                                </Grid>
                              <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        id="standard-select-currency-native"
                                        select
                                        label="Company Name"
                                        error={Boolean(formikProps.touched.companyId && formikProps.errors.companyId)}
                                        fullWidth
                                        {...formikProps.getFieldProps('companyId')}
                                        helperText={formikProps.touched.companyId && formikProps.errors.companyId}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        {companies.map((company: any) => (
                                            <option key={company._id} value={company._id}>
                                                {company.companyName}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        label="Role"
                                        error={Boolean(formikProps.touched.role && formikProps.errors.role)}
                                        fullWidth
                                        {...formikProps.getFieldProps('role')}
                                        helperText={formikProps.touched.role && formikProps.errors.role}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        <option key="" value="">
                                            {"Select Role"}
                                        </option>
                                        <option value="ADMIN">
                                            Admin
                                        </option>
                                        <option value="MANAGER">
                                            Manager
                                        </option>
                                        <option value="SUBSCRIBER">
                                            Subscriber
                                        </option>
                                        <option value="SUPPLIER">
                                            Supplier
                                        </option>
                                        <option value="ACCOUNTANT">
                                            Accountant
                                        </option>
                                        <option value="APIMANAGER">
                                            Api Manager
                                        </option>
                                        <option value="BDE">
                                            BDE
                                        </option>
                                    </TextField>

                                </Grid>

                               {formikProps.values.role == 'SUPPLIER' && <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        label="Supplier"
                                        error={Boolean(formikProps.touched.supplierId && formikProps.errors.supplierId)}
                                        fullWidth
                                        {...formikProps.getFieldProps('supplierId')}
                                        helperText={formikProps.touched.supplierId && formikProps.errors.supplierId}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        <option key="" value="">
                                            {"Select Supplier"}
                                        </option>
                                        {suppliers.map((supplier: Supplier) => (
                                            <option key={supplier._id} value={supplier._id}>
                                                {supplier.supplierName}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>}


                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.phoneNumber && formikProps.errors.phoneNumber)}
                                        fullWidth
                                        {...formikProps.getFieldProps('phoneNumber')}
                                        helperText={formikProps.touched.phoneNumber && formikProps.errors.phoneNumber}
                                        label="Phone Number"
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        autoComplete="current-password"
                                        label="Password"
                                        {...formikProps.getFieldProps('password')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={()=>formikProps.setFieldValue('password',makeid(10))}
                                                    edge="end"
                                                >
                                                    <TrackChangesIcon />
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                        error={Boolean(formikProps.touched.password && formikProps.errors.password)}
                                        helperText={formikProps.touched.password && formikProps.errors.password}
                                    />
                                </Grid>

                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {initialValues ? 'Save User' : 'Create User'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}
