import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { UserForm } from 'src/components/admin/_dashboard/user';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';
import { CompanyUserAddRequest, UserAddRequest } from 'src/actions/userActions';
import TopBarForm from 'src/components/shared/TopBarForm';
import { adminInfoProps } from 'src/types/adminTypes';

export default function AddUser() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const initialValues: any = null;

    const submitForm = async (values: adminInfoProps, setSubmitting: any) => {
        setTimeout(() => dispatch(UserAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Add User"  link="/admin/user"/>
            <UserForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
