import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Switch, Box, Tooltip, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { MoreMenu, DataGridRender } from 'src/components/table';
import MessageContext from 'src/context/MessageContext';
import { UserAllRequest, UserDeleteRequest, UserStatusUpdateRequest } from 'src/actions/userActions';
import TopBar from 'src/components/shared/TopBar';
import { GridRenderCellParams } from '@mui/x-data-grid';
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';

export default function UserList() {
  const { setMessageHandler } = useContext<any>(MessageContext);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [search, setSearch] = useState<string>('');

  const dispatch = useDispatch();
  const {loading, metadata, data } = useSelector(state => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(UserAllRequest(filters, search, pageNo, limit, status));
  }, [pageNo, search, limit, status, filters]);

  const deleteAction = (id: string) => {
    dispatch(UserDeleteRequest(id, { isDeleted: true }, setMessageHandler));
  }
  const editAction = (id: string) => {
    navigate(`/admin/user/${id}/edit`);
  }

  const columns = [
    { field: 'firstName', type: "string", headerName: 'Name',hide:true, filterable: false, headerAlign: "left", align: 'left', flex: 1 },
    { field: 'lastName', type: "string", headerName: 'Name', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({  value ,row }: GridRenderCellParams) =>
        <Tooltip title={row['firstName']+" "+row['lastName']}>
          <Typography variant="subtitle2">{row['firstName']+" "+row['lastName']}</Typography>
        </Tooltip>
    },
    { field: 'email', type: "string", headerName: 'Email', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ value }: GridRenderCellParams) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    // { field: 'companyName', type: "string", headerName: 'Company Name', filterable: false, headerAlign: "left", align: 'left',flex: 1},
    { field: 'role', type: "string", headerName: 'Role', filterable: false, width: 150,headerAlign: "center", align: 'center', renderCell: ({ value }: GridRenderCellParams) =>
        <Label
          variant="ghost"
          color='primary'
        >
          {sentenceCase(value)}
        </Label>
    },
    { field: 'isActive', headerName: 'Status', type: "boolean", width: 150, renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(UserStatusUpdateRequest(params.id, { isActive: !params.value }, setMessageHandler))} />
      }
    },
    { field: 'actions', headerName: '', width: 60, type: 'actions', getActions: ({ id,row }: any) => [<MoreMenu id={id} name={row['firstName']+" "+row['lastName']} cloneAction="" addAction='' editAction={editAction} deleteAction={deleteAction} exportAction="" />] },
  ];


  return (
    <Container maxWidth={false}>
      <TopBar title="Users" link="/admin/user/add" buttonText="New User" />
      <DataGridRender
        width={1}
        height={620}
        loading={loading}
        filterTypes={['status','export']}
        exportFileName="Users"
        filters={filters}
        data={data}
        metadata={metadata}
        limit={limit}
        search={search}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setSearch={setSearch}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}
