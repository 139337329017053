import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Switch, Box, Tooltip, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { MoreMenu, DataGridRender } from 'src/components/table';
import MessageContext from 'src/context/MessageContext';
import ReactCountryFlag from "react-country-flag"
import TopBar from 'src/components/shared/TopBar';
import { CompanyAllRequest, CompanyDeleteRequest } from 'src/actions/companyActions';

export default function CompanyList() {
  const { setMessageHandler } = useContext<any>(MessageContext);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [search, setSearch] = useState<string>('');

  const dispatch = useDispatch();
  const {loading, metadata, data } = useSelector(state => state.company);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(CompanyAllRequest(filters, search, pageNo, limit, status));
  }, [pageNo, search, limit, status, filters]);

  const deleteAction = (id: string) => {
    dispatch(CompanyDeleteRequest(id, { isDeleted: true }, setMessageHandler));
  }
  const editAction = (id: string) => {
    navigate(`/admin/company/${id}/edit`);
  }

  const columns = [
    { field: 'companyName', type: "string", headerName: 'Company Name',hide:false, filterable: false, headerAlign: "left", align: 'left', flex: 1 },
    { field: 'companyEmail', type: "string", headerName: 'Company Email', filterable: false, headerAlign: "left", align: 'left', flex: 1 },
    {
      field: 'countryName', type: "string", headerName: 'Country', filterable: false, flex: 1, headerAlign: "left", align: 'left',
      renderCell: (params: any) => {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ReactCountryFlag className="emojiFlag" countryCode={`${params.row.countryCode}`} /><Tooltip title={params.row.countryName}>
            <Typography sx={{ ml: 1 }} variant="subtitle2">{params.row.countryName}</Typography>
          </Tooltip>
        </Box>
      },
    },
    {
      field: 'currency', type: "string", headerName: 'Currency', filterable: false, flex: 1, headerAlign: "left", align: 'left',
      renderCell: (params: any) => {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ ml: 1 }} variant="subtitle2">{params.row.currency}</Typography>
        </Box>
      },
    },
    { field: 'actions', headerName: '', width: 60, type: 'actions', getActions: ({ id,row }: any) => [<MoreMenu id={id} name={row['firstName']+" "+row['lastName']} cloneAction="" addAction='' editAction={editAction} deleteAction={deleteAction} exportAction="" />] },
  ];


  return (
    <Container maxWidth={false}>
      <TopBar title="Companies" link="/admin/company/add" buttonText="New Company" />
      <DataGridRender
        width={1}
        height={620}
        loading={loading}
        filterTypes={['status','export']}
        exportFileName="Users"
        filters={filters}
        data={data}
        metadata={metadata}
        limit={limit}
        search={search}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setSearch={setSearch}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}
