import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { UserForm } from 'src/components/admin/_dashboard/user';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';
import { CompanyUserAddRequest } from 'src/actions/userActions';
import TopBarForm from 'src/components/shared/TopBarForm';
import { adminInfoProps } from 'src/types/adminTypes';
import { CompanyUserForm } from 'src/components/admin/_dashboard/companyUser';

export default function AddCompanyUser() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const initialValues: any = null;

    const submitForm = async (values: adminInfoProps, setSubmitting: any) => {
        setTimeout(() => dispatch(CompanyUserAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Add User"  link="/admin/company/user"/>
            <CompanyUserForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
