import { styled } from '@mui/material/styles';
import { Card, Stack, Grid, TextField, IconButton, InputAdornment } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup'
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { Company } from 'src/types/companyTypes';
import { makeid } from 'src/utils/common';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { CountryAllRequest, CurrenciesAllRequest } from 'src/actions/countryActions';
import { useDispatch } from 'react-redux';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface SubmitFormValues {
    initialValues: Company,
    submitForm: any,
}

export default function CompanyForm({ initialValues, submitForm }: SubmitFormValues) {

    const SchemaCompanyForm = Yup.object().shape({
        companyName: Yup.string().min(4).trim().required('Company name  is required'),
        companyEmail: Yup.string().email().trim().required('Company email  is required'),
        firstName: Yup.string().min(4).trim().required('First name  is required'),
        lastName: Yup.string().trim().required('Last name is required'),
        email: Yup.string().email().required('Email is required'),
        countryId: Yup.string().required('Country is required'),
    });
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { redirectTo } = useSelector(state => state.company);
    const { countries ,currencies } = useSelector(state => state.country);

    useEffect(() => {
        if (redirectTo) navigate(redirectTo, { replace: true })
    }, [redirectTo])


    useEffect(() => {
        dispatch(CountryAllRequest())
        dispatch(CurrenciesAllRequest())
    }, [])
    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaCompanyForm}
                initialValues={{
                    companyName: initialValues?.companyName || '',
                    companyEmail: initialValues?.companyEmail || '',
                    countryId: initialValues?.countryId || '',
                    currencyId: initialValues?.currencyId || "622b3eb0be73c54656d45ca0",
                    firstName: initialValues?.firstName || '',
                    lastName: initialValues?.lastName || '',
                    email: initialValues?.email || '',
                    password: initialValues?.password || '',
                }}
            >
                {(formikProps: FormikProps<Company>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.companyName && formikProps.errors.companyName)}
                                        fullWidth
                                        helperText={formikProps.touched.companyName && formikProps.errors.companyName}
                                        label="Company Name"
                                        variant="standard"
                                        {...formikProps.getFieldProps('companyName')}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.companyEmail && formikProps.errors.companyEmail)}
                                        fullWidth
                                        helperText={formikProps.touched.companyEmail && formikProps.errors.companyEmail}
                                        label="Company Email"
                                        variant="standard"
                                        {...formikProps.getFieldProps('companyEmail')}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        id="standard-select-currency-native"
                                        select
                                        label="Country Name"
                                        error={Boolean(formikProps.touched.countryId && formikProps.errors.countryId)}
                                        fullWidth
                                        {...formikProps.getFieldProps('countryId')}
                                        helperText={formikProps.touched.countryId && formikProps.errors.countryId}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        <option key="" value="">
                                            {"Select Country"}
                                        </option>
                                        {countries.map((country: any) => (
                                            <option key={country._id} value={country._id}>
                                                {country.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        id="standard-select-currency-native"
                                        select
                                        label="Currency"
                                        error={Boolean(formikProps.touched.currencyId && formikProps.errors.currencyId)}
                                        fullWidth
                                        {...formikProps.getFieldProps('currencyId')}
                                        helperText={formikProps.touched.currencyId && formikProps.errors.currencyId}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        <option key="" value="">
                                            {"Select Currency"}
                                        </option>
                                        {currencies.map((currency: any) => (
                                            <option key={currency._id} value={currency._id}>
                                                  {currency.code}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                {!initialValues &&    <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.firstName && formikProps.errors.firstName)}
                                        fullWidth
                                        helperText={formikProps.touched.firstName && formikProps.errors.firstName}
                                        label="First Name"
                                        variant="standard"
                                        {...formikProps.getFieldProps('firstName')}
                                    />
                                </Grid>}
                                {!initialValues &&  <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.lastName && formikProps.errors.lastName)}
                                        fullWidth
                                        helperText={formikProps.touched.lastName && formikProps.errors.lastName}
                                        label="Last Name"
                                        variant="standard"
                                        {...formikProps.getFieldProps('lastName')}
                                    />
                                </Grid>}
                                {!initialValues &&  <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.email && formikProps.errors.email)}
                                        fullWidth
                                        {...formikProps.getFieldProps('email')}
                                        helperText={formikProps.touched.email && formikProps.errors.email}
                                        label="Email"
                                        variant="standard"
                                    />
                                </Grid>}

                              {!initialValues &&  <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        autoComplete="current-password"
                                        label="Password"
                                        {...formikProps.getFieldProps('password')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => formikProps.setFieldValue('password', makeid(10))}
                                                    edge="end"
                                                >
                                                    <TrackChangesIcon />
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                        error={Boolean(formikProps.touched.password && formikProps.errors.password)}
                                        helperText={formikProps.touched.password && formikProps.errors.password}
                                    />
                                </Grid>}

                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {initialValues ? 'Save Company' : 'Create Company'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}
