import { styled } from '@mui/material/styles';
import { Card, Stack,  Grid } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import Editor from 'react-simple-wysiwyg';
import {  useRef } from 'react';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface FormValues {
    submitForm: any,
    initialValues:any
}

export default function EmailForm({ submitForm,initialValues }: FormValues) {

    const editorRef: any = useRef(null);
    const SchemaCategoryForm = Yup.object().shape({
        emailBody: Yup.string().required('Body is required'),
    });

    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaCategoryForm}
                initialValues={{
                    projectId:initialValues.projectId,
                    emailBody: '',
                }}
            >
                {(formikProps: FormikProps<any>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >   

                               <Editor
                               containerProps={{ style: { resize: 'vertical' } }}
                                value={formikProps.values.emailBody} 
                                onChange={(e) =>   formikProps.setFieldValue('emailBody', e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {'Send Email'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}
