import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { companyAction } from "src/reducers/companyReducer";
import { companyActionTypes } from "src/types/actionTypes";
import { adminInfoProps } from "src/types/adminTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import {  CompanyList } from "src/types/companyTypes";

export const CompanyFailure = (message: string): ErrorAction => {
    return {
        type: companyActionTypes.COMPANY_REQUEST,
        payload: { message: message }
    }
}
export const CompanySuccess = (payload: CompanyList): SuccessAction<CompanyList> => {
    return {
        type: companyActionTypes.FETCH_COMPANY_SUCCESS,
        payload: payload,
    }
}

export const CompanySuccessAll = (payload: CompanyList): SuccessAction<CompanyList> => {
    return {
        type: companyActionTypes.FETCH_COMPANY_SUCCESS_ALL,
        payload: payload,
    }
}

export const CompanyAddSuccess = (payload: adminInfoProps): SuccessAction<adminInfoProps> => {
    return {
        type: companyActionTypes.ADD_COMPANY_SUCCESS,
        payload: payload,
    }
}
export const CompanySingleSuccess = (payload: adminInfoProps): SuccessAction<adminInfoProps> => {
    return {
        type: companyActionTypes.FETCH_SINGLE_COMPANY_SUCCESS,
        payload: payload,
    }
}

export const CompanyUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: companyActionTypes.UPDATE_COMPANY_SUCCESS,
        payload: payload,
    }
}


export const CompanyDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: companyActionTypes.DELETE_COMPANY_SUCCESS,
        payload: payload,
    }
}


export const CompanyAllRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, companyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, companyAction>) => {
        dispatch({ type:companyActionTypes.COMPANY_REQUEST,payload: { loading:true} });
        return AXIOS.post(`${Prefix.api}/admin/companies`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const companys: CompanyList = data.result
                dispatch(CompanySuccess(companys))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CompanyFailure(e.response.data.message));
            }
            else {
                dispatch(CompanyFailure(e.message));
            }
        })
    }
}

export const CompanyAllSupplierRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, companyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, companyAction>) => {
        dispatch({ type:companyActionTypes.COMPANY_REQUEST,payload: { loading:true} });
        return AXIOS.post(`${Prefix.api}/admin/companys/supplier`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const companys: CompanyList = data.result
                dispatch(CompanySuccess(companys))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CompanyFailure(e.response.data.message));
            }
            else {
                dispatch(CompanyFailure(e.message));
            }
        })
    }
}


export const CompanyAllRequestDataAdmin = ():
    ThunkAction<Promise<void>, {}, {}, companyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, companyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/companys/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const companys: CompanyList = data.result
                dispatch(CompanySuccessAll(companys))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CompanyFailure(e.response.data.message));
            }
            else {
                dispatch(CompanyFailure(e.message));
            }
        })
    }
}
export const CompanyAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, companyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, companyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/companies/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const companys: CompanyList = data.result
                dispatch(CompanySuccessAll(companys))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CompanyFailure(e.response.data.message));
            }
            else {
                dispatch(CompanyFailure(e.message));
            }
        })
    }
}


export const CompanyAllRequestBdeData = ():
    ThunkAction<Promise<void>, {}, {}, companyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, companyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/companys/role/bde`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const companys: CompanyList = data.result
                dispatch(CompanySuccessAll(companys))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CompanyFailure(e.response.data.message));
            }
            else {
                dispatch(CompanyFailure(e.message));
            }
        })
    }
}




export const CompanyAddRequest = (company: adminInfoProps, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, companyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, companyAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/company/add`, company, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const company: adminInfoProps = data.result;
                dispatch(CompanyAddSuccess(company))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(CompanyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(CompanyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.message,
                });
            }
        })
    }
}


export const CompanyUpdateRequest = (catid: string, company: adminInfoProps, setMessageHandler:any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, companyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, companyAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/company/update/${catid}`, company, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const company: adminInfoProps = data.result;
                dispatch(CompanyUpdateSuccess(company))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(CompanyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(CompanyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.message,
                });
            }
        })
    }
}
export const CompanyStatusUpdateRequest = (catid: string, company: adminInfoProps,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, companyAction> => {
        setMessageHandler({
            hasMessage: false,
            type: '',
            message: '',
        });
    return (dispatch: ThunkDispatch<{}, {}, companyAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/company/update/${catid}`, company, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const company: adminInfoProps = data.result;
                dispatch(CompanyUpdateSuccess(company))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CompanyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(CompanyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.message,
                });
            }
        })
    }
}


export const CompanySingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, companyAction> => {
        
    return (dispatch: ThunkDispatch<{}, {}, companyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/company/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const company: adminInfoProps = data.result;
                dispatch(CompanySingleSuccess(company));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CompanyFailure(e.response.data.message));
            }
            else {
                dispatch(CompanyFailure(e.message));
            }
        })
    }
}


export const CompanyDeleteRequest = (id: string, body: adminInfoProps,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, companyAction> => {
        setMessageHandler({
            hasMessage: false,
            type: '',
            message: '',
        });
    return (dispatch: ThunkDispatch<{}, {}, companyAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/company/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const company: adminInfoProps = data.result;
                dispatch(CompanyDeleteSuccess(company))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CompanyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(CompanyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.response.data.message,
                });
            }
        })
    }
}





