import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import DashboardApp from 'src/pages/Admin/Dashboard/DashboardApp';
import ClientList from 'src/pages/Admin/Client/ClientList';
import AddClient from 'src/pages/Admin/Client/AddClient';
import SupplierList from 'src/pages/Admin/Supplier/SupplierList';
import AddSupplier from 'src/pages/Admin/Supplier/AddSupplier';
import EditSupplier from 'src/pages/Admin/Supplier/EditSupplier';
import ProjectList from 'src/pages/Admin/Project/ProjectList';
import FetchProjectList from 'src/pages/Admin/Project/FetchProjectList';
import AddProject from 'src/pages/Admin/Project/AddProject';
import EditProject from 'src/pages/Admin/Project/EditProject';
import AddChildProject from 'src/pages/Admin/Project/AddChildProject';
import ChildProjectList from 'src/pages/Admin/Project/ChildProjectList';
import EditChildProject from 'src/pages/Admin/Project/EditChildProject';
import CategoryList from 'src/pages/Admin/Library/Category/CategoryList';
import AddCategory from 'src/pages/Admin/Library/Category/AddCategory';
import EditClient from 'src/pages/Admin/Client/EditClient';
import EditCategory from 'src/pages/Admin/Library/Category/EditCategory';
import IdentifierList from 'src/pages/Admin/Service/Identifier/IdentifierList';
import UserList from 'src/pages/Admin/User/UserList';
import AddUser from 'src/pages/Admin/User/AddUser';
import EditUser from 'src/pages/Admin/User/EditUser';
import { Navigate } from 'react-router-dom';
import SupplierProjectList from 'src/pages/Supplier/Project/SupplierProjectList';
import SupplierProjectInventory from 'src/pages/Supplier/Inventory/SupplierProjectInventory';
import ProjectReport from 'src/pages/Admin/Report/Project/ProjectReport';
import ShortCode from 'src/pages/Admin/Service/ShortCode/ShortCode';
import InvoiceProjectList from 'src/pages/Admin/Invoice/InvoiceProjectList';
import ReconcileProjectList from 'src/pages/Admin/Reconcile/ReconcileProjectList';
import GenerateInvoice from 'src/pages/Admin/Invoice/GenerateInvoice';
import EditProjectInvoice from 'src/pages/Admin/Invoice/EditProjectInvoice';
import InvoiceList from 'src/pages/Admin/Invoice/InvoiceList';
import EditGenerateInvoice from 'src/pages/Admin/Invoice/EditGenerateInvoice';
import ViewInvoice from 'src/pages/Admin/Invoice/ViewInvoice';
import EditInvoiceStatus from 'src/pages/Admin/Invoice/EditInvoiceStatus';
import QuestionList from 'src/pages/Admin/Library/Question/QuestionList';
import SystemLog from 'src/pages/Admin/Service/SystemLog/SystemLog';
import ScheduleJob from 'src/pages/Admin/Service/ScheduleJob/ScheduleJob';
import AddScheduleJob from 'src/pages/Admin/Service/ScheduleJob/AddScheduleJob';
import EditScheduleJob from 'src/pages/Admin/Service/ScheduleJob/EditScheduleJob';
import ArchievedProjectList from 'src/pages/Admin/Invoice/ArchievedProjectList';
import SupplierRuleList from 'src/pages/Admin/Library/SupplierRule/SupplierRuleList';
import AddSupplierRule from 'src/pages/Admin/Library/SupplierRule/AddSupplierRule';
import UserSupplierList from 'src/pages/Admin/User/UserSupplierList';
import InvoiceSupplierList from 'src/pages/Admin/Invoice/InvoiceSupplierList';
import PurchaseOrderSupplierList from 'src/pages/Admin/Invoice/PurchaseOrderSupplierList';
import ProjectSupplierList from 'src/pages/Admin/Invoice/ProjectSupplierList';
import QualificationList from 'src/pages/Admin/Library/Qualification/QualificationList';
import GenerateInvoiceManual from 'src/pages/Admin/Invoice/GenerateInvoiceManual';
import PrecisionProjectList from 'src/pages/Admin/Project/PrecisionProjectList';
import LogitProjectList from 'src/pages/Admin/Project/LogitProjectList';
import TolunaProjectList from 'src/pages/Admin/Project/TolunaProjectList';
import FullCircleProjectList from 'src/pages/Admin/Project/FullCircleProjectList';
import MrktProjectList from 'src/pages/Admin/Project/MrktProjectList';
import MessageList from 'src/pages/Admin/Library/Message/MessageList';
import AddMessage from 'src/pages/Admin/Library/Message/AddMessage';
import EditMessage from 'src/pages/Admin/Library/Message/EditMessage';
import CompanyList from 'src/pages/Admin/Company/CompanyList';
import EditCompany from 'src/pages/Admin/Company/EditCompany';
import AddCompany from 'src/pages/Admin/Company/AddCompany';

import CompanyUserList from 'src/pages/Admin/CompanyUser/CompanyUserList';
import AddCompanyUser from 'src/pages/Admin/CompanyUser/AddCompanyUser';
import EditCompanyUser from 'src/pages/Admin/CompanyUser/EditCompanyUser';



const getIcon = (name: any) => <Icon icon={name} width={22} height={22} />;

const adminRoutes = [
  { path: '/', show:false, title: 'dashboard',roles:['SUBSCRIBER','APIMANAGER','SUPERADMIN','ADMIN','MANAGER','ACCOUNTANT','SUPPLIER','BDE'], icon: getIcon(pieChart2Fill), element: <Navigate to="/login" replace />  },
  { path: '/admin/*',show:false, title: 'dashboard',roles:['SUBSCRIBER','APIMANAGER','ADMIN','SUPERADMIN','MANAGER','ACCOUNTANT','BDE'], icon: getIcon(pieChart2Fill), element: <Navigate to="/admin/dashboard" replace />  },
  { path: '/admin/dashboard',show:true, title: 'dashboard',roles:['SUBSCRIBER','APIMANAGER','ADMIN','SUPERADMIN','MANAGER','ACCOUNTANT','BDE'], icon: getIcon(pieChart2Fill), element: <DashboardApp /> },
  { path: '/admin/project',show:true,  title: 'projects', roles:['SUBSCRIBER','APIMANAGER','ADMIN','MANAGER','BDE'],icon: getIcon('ant-design:project-filled'), element: <ProjectList /> },
  { path: '/admin/project/fetch',show:false,  title: 'projects', roles:['SUBSCRIBER','ADMIN','MANAGER'],icon: getIcon('ant-design:project-filled'), element: <FetchProjectList /> },
  
  { path: '/admin/api',show:true,  title: 'Api Projects', roles:['APIMANAGER'],icon: getIcon('ant-design:project-filled'), element: <PrecisionProjectList /> },
  { path: '/admin/api/logit',show:false,  title: 'Api Projects', roles:['APIMANAGER'],icon: getIcon('ant-design:project-filled'), element: <LogitProjectList /> },
  { path: '/admin/api/toluna',show:false,  title: 'Api Projects', roles:['APIMANAGER'],icon: getIcon('ant-design:project-filled'), element: <TolunaProjectList /> },
  { path: '/admin/api/circle',show:false,  title: 'Api Projects', roles:['APIMANAGER'],icon: getIcon('ant-design:project-filled'), element: <FullCircleProjectList /> },
  { path: '/admin/api/mrkt',show:false,  title: 'Api Projects', roles:['APIMANAGER'],icon: getIcon('ant-design:project-filled'), element: <MrktProjectList /> },
  
  { path: '/admin/project/add',show:false, title: 'projects', roles:['APIMANAGER','ADMIN','MANAGER'],icon: getIcon('ant-design:project-filled'), element: <AddProject /> },
  { path: '/admin/project/child/add',show:false,title: 'projects', roles:['ADMIN','APIMANAGER','MANAGER'],icon: getIcon('ant-design:project-filled'), element: <AddChildProject /> },
  { path: '/admin/project/child/:projectid/add',show:false,title: 'projects', roles:['ADMIN','APIMANAGER','MANAGER'],icon: getIcon('ant-design:project-filled'), element: <AddChildProject /> },
  { path: '/admin/project/:projectid/edit',show:false,title: 'projects', roles:['ADMIN','APIMANAGER','MANAGER'],icon: getIcon('ant-design:project-filled'), element: <EditProject /> },
  { path: '/admin/project/:projectid/childs',show:false,title: 'projects',  roles:['ADMIN','APIMANAGER','MANAGER'], icon: getIcon('ant-design:project-filled'),element: <ChildProjectList /> },
  { path: '/admin/project/child/:projectid/edit',show:false,title: 'projects',roles:['ADMIN','APIMANAGER','MANAGER'],icon: getIcon('ant-design:project-filled'), element: <EditChildProject /> },
  
  { path: '/admin/client',show:true, title: 'clients', roles:['ADMIN','ACCOUNTANT','BDE'],icon: getIcon(peopleFill), element: <ClientList  /> },
  { path: '/admin/client/add',show:false, title: 'clients',roles:['ADMIN','ACCOUNTANT','BDE'],icon: getIcon(peopleFill), element: <AddClient /> },
  { path: '/admin/client/:clientid/edit',show:false, title: 'clients', roles:['ADMIN','ACCOUNTANT','BDE'],icon: getIcon(peopleFill), element: <EditClient /> },
 
  { path: '/admin/supplier',show:true, title: 'suppliers', roles:['ADMIN','ACCOUNTANT'], icon: getIcon('carbon:two-person-lift'), element: <SupplierList /> },
  { path: '/admin/supplier/add',show:false,title: 'suppliers', roles:['ADMIN','ACCOUNTANT'], icon: getIcon('carbon:two-person-lift'), element: <AddSupplier /> },
  { path: '/admin/supplier/:supplierid/edit',show:false,title: 'suppliers', roles:['ADMIN','ACCOUNTANT'],icon: getIcon('carbon:two-person-lift'), element: <EditSupplier /> },
 
  { path: '/admin/report',show:true,  title: 'reports', roles:['SUBSCRIBER','ACCOUNTANT','ADMIN','MANAGER','BDE'],icon: getIcon('carbon:report'), element: <ProjectReport /> },
 
  { path: '/admin/reconcile', title: 'reconciles',show:true, roles:['ADMIN','APIMANAGER','MANAGER','ACCOUNTANT'],icon: getIcon('ant-design:cloud-upload-outlined'), element: <ReconcileProjectList /> },
  { path: '/admin/reconcile/add', title: 'reconciles',show:false, roles:['ADMIN','MANAGER','APIMANAGER','ACCOUNTANT'],icon: getIcon('ant-design:cloud-upload-outlined'), element: <AddUser /> },
  { path: '/admin/reconcile/:reconcileid/edit', title: 'reconciles',show:false, roles:['ADMIN','MANAGER','APIMANAGER','ACCOUNTANT'], icon: getIcon('ant-design:cloud-upload-outlined'),element: <EditUser /> },
  
  { path: '/admin/invoice', title: 'accounts',show:true, roles:['ADMIN','ACCOUNTANT'],icon: getIcon('fa6-solid:file-invoice-dollar'), element: <InvoiceProjectList /> },
  { path: '/admin/invoice/list', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'],icon: getIcon('fa6-solid:file-invoice-dollar'), element: <InvoiceList /> },
  { path: '/admin/invoice/supplier', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'],icon: getIcon('fa6-solid:file-invoice-dollar'), element: <InvoiceSupplierList /> },
  { path: '/admin/invoice/supplier/list', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'],icon: getIcon('fa6-solid:file-invoice-dollar'), element: <PurchaseOrderSupplierList /> },
  { path: '/admin/invoice/supplier/:supplierid/list', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'],icon: getIcon('fa6-solid:file-invoice-dollar'), element: <ProjectSupplierList /> },
  { path: '/admin/invoice/archieved', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'],icon: getIcon('fa6-solid:file-invoice-dollar'), element: <ArchievedProjectList /> },
  { path: '/admin/invoice/generate', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'],icon: getIcon('fa6-solid:file-invoice-dollar'), element: <GenerateInvoice /> },
  { path: '/admin/invoice/generate/manual', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'],icon: getIcon('fa6-solid:file-invoice-dollar'), element: <GenerateInvoiceManual /> },
  { path: '/admin/invoice/add', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'],icon: getIcon('fa6-solid:file-invoice-dollar'), element: <AddUser /> },
  { path: '/admin/invoice/:projectid/edit', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'], icon: getIcon('fa6-solid:file-invoice-dollar'),element: <EditProjectInvoice /> },
  { path: '/admin/invoice/generate/:invoiceid/edit', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'], icon: getIcon('fa6-solid:file-invoice-dollar'),element: <EditGenerateInvoice /> },
  { path: '/admin/invoice/status/:invoiceid/edit', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'], icon: getIcon('fa6-solid:file-invoice-dollar'),element: <EditInvoiceStatus /> },
  { path: '/admin/invoice/view/:invoiceid', title: 'invoices',show:false, roles:['ADMIN','ACCOUNTANT'], icon: getIcon('fa6-solid:file-invoice-dollar'),element: <ViewInvoice /> },
  
  { path: '/admin/company', title: 'companies',show:true, roles:['SUPERADMIN'],icon: getIcon('bi:person-fill'), element: <CompanyList /> },
  { path: '/admin/company/add', title: 'companies',show:false, roles:['SUPERADMIN'],icon: getIcon('bi:person-fill'), element: <AddCompany /> },
  { path: '/admin/company/:companyid/edit', title: 'companies',show:false, roles:['SUPERADMIN'], icon: getIcon('bi:person-fill'),element: <EditCompany /> },
 
  { path: '/admin/company/user', title: ' Users',show:true, roles:['SUPERADMIN'],icon: getIcon('bi:person-fill'), element: <CompanyUserList /> },
  { path: '/admin/company/user/add', title: ' Users',show:false, roles:['SUPERADMIN'],icon: getIcon('bi:person-fill'), element: <AddCompanyUser /> },
  { path: '/admin/company/user/:userid/edit', title: 'Company Users',show:false, roles:['SUPERADMIN'], icon: getIcon('bi:person-fill'),element: <EditCompanyUser /> },

  { path: '/admin/user', title: 'users',show:true, roles:['ADMIN'],icon: getIcon('bi:person-fill'), element: <UserList /> },
  { path: '/admin/user/supplier', title: 'users',show:false, roles:['ADMIN'],icon: getIcon('bi:person-fill'), element: <UserSupplierList /> },
  { path: '/admin/user/add', title: 'users',show:false, roles:['ADMIN'],icon: getIcon('bi:person-fill'), element: <AddUser /> },
  { path: '/admin/user/:userid/edit', title: 'users',show:false, roles:['ADMIN'], icon: getIcon('bi:person-fill'),element: <EditUser /> },


  { path: '/admin/library',title: 'library',show:true,  roles:['ADMIN','MANAGER'],icon: getIcon('ic:baseline-local-library'), element: <CategoryList /> },
  { path: '/admin/library/category/add',title: 'library',show:false,  roles:['ADMIN','MANAGER'],icon: getIcon('ic:baseline-local-library'), element: <AddCategory /> },
  { path: '/admin/library/category/:catid/edit',title: 'library',show:false,  roles:['ADMIN','MANAGER'],icon: getIcon('ic:baseline-local-library'), element: <EditCategory /> },
  { path: '/admin/library/question', title: 'library',show:false, roles:['ADMIN'],icon: getIcon('ic:baseline-local-library'), element: <QuestionList /> },
  { path: '/admin/library/qualification', title: 'library',show:false, roles:['ADMIN'],icon: getIcon('ic:baseline-local-library'), element: <QualificationList /> },
  { path: '/admin/library/question/add', title: 'library',show:false, roles:['ADMIN'],icon: getIcon('ic:baseline-local-library'), element: <AddUser /> },
  { path: '/admin/library/question/:questionid/edit', title: 'library',show:false, roles:['ADMIN'], icon: getIcon('ic:baseline-local-library'),element: <EditUser /> },
  { path: '/admin/library/rule', title: 'library',show:false, roles:['ADMIN'],icon: getIcon('ic:baseline-local-library'), element: <SupplierRuleList /> },
  { path: '/admin/library/rule/add', title: 'library',show:false, roles:['ADMIN'],icon: getIcon('ic:baseline-local-library'), element: <AddSupplierRule /> },
  { path: '/admin/library/message', title: 'library',show:false, roles:['ADMIN'], icon: getIcon('ic:baseline-local-library'),element: <MessageList /> },
  { path: '/admin/library/message/add', title: 'library',show:false, roles:['ADMIN'],icon: getIcon('ic:baseline-local-library'), element: <AddMessage /> },
  { path: '/admin/library/message/:messageid/edit', title: 'library',show:false, roles:['ADMIN'], icon: getIcon('ic:baseline-local-library'),element: <EditMessage /> },

  { path: '/admin/support',title: 'support', show:true, roles:['SUBSCRIBER','APIMANAGER','ACCOUNTANT','ADMIN','MANAGER'],icon:  getIcon('icons8:support'), element: <IdentifierList /> },
  { path: '/admin/support/shortcode',title: 'services', show:false, roles:['SUBSCRIBER','ACCOUNTANT','ADMIN','MANAGER'],icon: getIcon('flat-color-icons:support'), element: <ShortCode /> },
  { path: '/admin/support/log',title: 'services', show:false, roles:['ACCOUNTANT','ADMIN'],icon: getIcon('flat-color-icons:support'), element: <SystemLog /> },
  { path: '/admin/support/job',title: 'services', show:false, roles:['ACCOUNTANT','ADMIN','MANAGER'],icon: getIcon('flat-color-icons:support'), element: <ScheduleJob /> },
  { path: '/admin/support/job/add',title: 'services', show:false, roles:['ACCOUNTANT','ADMIN','MANAGER'],icon: getIcon('flat-color-icons:support'), element: <AddScheduleJob /> },
  { path: '/admin/support/job/:jobid/edit',title: 'services', show:false, roles:['ACCOUNTANT','ADMIN','MANAGER'],icon: getIcon('flat-color-icons:support'), element: <EditScheduleJob /> },

  { path: '/supplier/*',show:false, title: 'dashboard',roles:['SUPPLIER'], icon: getIcon(pieChart2Fill), element: <Navigate to="/supplier/project" replace />  },
  { path: '/supplier/project', title: 'projects',show:true,roles:['SUPPLIER'],icon: getIcon('ant-design:project-filled'), element: <SupplierProjectList /> },
  { path: '/supplier/inventory', title: 'inventory',show:true,roles:['SUPPLIER'],icon: getIcon('ic:baseline-inventory'),  element: <SupplierProjectInventory /> },
];


  
export  { adminRoutes };
