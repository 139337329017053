export const adminActionTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',
    ADMIN_USER_SUCCESS:'ADMIN_USER_SUCCESS'
}

export const forgetActionTypes = {
    EMAIL_SEND_REQUEST:'EMAIL_SEND_REQUEST',
    EMAIL_SEND_SUCCESS:'EMAIL_SEND_SUCCESS',
    EMAIL_SEND_FAILURE:'EMAIL_SEND_FAILURE',
}
export const dashboardActionTypes = {
    DASHBOARD_REQUEST: 'DASHBOARD_REQUEST',
    FETCH_DASHBOARD_BOX_SUCCESS: 'FETCH_DASHBOARD_BOX_SUCCESS',
    FETCH_DASHBOARD_PROJECT_LIST_SUCCESS: 'FETCH_DASHBOARD_PROJECT_LIST_SUCCESS',
    FETCH_DASHBOARD_PROJECT_LIST_SECURITY_SUCCESS: 'FETCH_DASHBOARD_PROJECT_LIST_SECURITY_SUCCESS',
    FETCH_DASHBOARD_PROJECT_MANAGER_SUCCESS: 'FETCH_DASHBOARD_PROJECT_MANAGER_SUCCESS',
    FETCH_DASHBOARD_PROJECT_STATUS_SUCCESS: 'FETCH_DASHBOARD_PROJECT_STATUS_SUCCESS',
    FETCH_DASHBOARD_PROJECT_REVENUE_SUCCESS:'FETCH_DASHBOARD_PROJECT_REVENUE_SUCCESS',
    FETCH_DASHBOARD_PROJECT_EXPENSE_SUCCESS:'FETCH_DASHBOARD_PROJECT_EXPENSE_SUCCESS',
    FETCH_DASHBOARD_PROJECT_TRAFFIC_SUCCESS:'FETCH_DASHBOARD_PROJECT_TRAFFIC_SUCCESS',
    FETCH_DASHBOARD_PROJECT_SECURITY_SUCCESS:'FETCH_DASHBOARD_PROJECT_SECURITY_SUCCESS',
    FETCH_DASHBOARD_PROJECT_EXPENSE_REVENUE_SUCCESS:'FETCH_DASHBOARD_PROJECT_EXPENSE_REVENUE_SUCCESS',
    FETCH_DASHBOARD_PROJECT_INVOICE_SUCCESS:"FETCH_DASHBOARD_PROJECT_INVOICE_SUCCESS",
    DASHBOARD_FAILURE: 'DASHBOARD_FAILURE',
}

export const userActionTypes = {
    USER_REQUEST: 'USER_REQUEST',
    FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
    FETCH_USER_SUCCESS_ALL: 'FETCH_USER_SUCCESS_ALL',
    FETCH_SINGLE_USER_SUCCESS: 'FETCH_SINGLE_USER_SUCCESS',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    USER_FAILURE: 'USER_FAILURE',
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS"
}

export const companyActionTypes = {
    COMPANY_REQUEST: 'COMPANY_REQUEST',
    FETCH_COMPANY_SUCCESS: 'FETCH_COMPANY_SUCCESS',
    FETCH_COMPANY_SUCCESS_ALL: 'FETCH_COMPANY_SUCCESS_ALL',
    FETCH_SINGLE_COMPANY_SUCCESS: 'FETCH_SINGLE_COMPANY_SUCCESS',
    ADD_COMPANY_SUCCESS: 'ADD_COMPANY_SUCCESS',
    UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
    COMPANY_FAILURE: 'COMPANY_FAILURE',
    DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS"
}

export const projectActionTypes = {
    PROJECT_REQUEST: 'PROJECT_REQUEST',
    FETCH_PROJECT_EXCLUDE_SUCCESS:'FETCH_PROJECT_EXCLUDE_SUCCESS',
    ADD_PROJECT_EXCLUDE_SUCCESS:'ADD_PROJECT_EXCLUDE_SUCCESS',
    UPDATE_PROJECT_EXCLUDE_SUCCESS:'UPDATE_PROJECT_EXCLUDE_SUCCESS',
    DELETE_PROJECT_EXCLUDE_SUCCESS:'DELETE_PROJECT_EXCLUDE_SUCCESS',
    FETCH_PROJECT_ALL_SUCCESS:'FETCH_PROJECT_ALL_SUCCESS',
    FETCH_ARCHIEVED_PROJECT_SUCCESS:'FETCH_ARCHIEVED_PROJECT_SUCCESS',
    FETCH_PROJECT_SUCCESS: 'FETCH_PROJECT_SUCCESS',
    FETCH_PROJECT_API_SUCCESS:'FETCH_PROJECT_API_SUCCESS',
    FETCH_PROJECT_QUOTA_API_SUCCESS:'FETCH_PROJECT_QUOTA_API_SUCCESS',
    FETCH_PROJECT_QUESTION_API_SUCCESS:'FETCH_PROJECT_QUESTION_API_SUCCESS',
    FETCH_CHILD_SEARCH_SUCCESS: 'FETCH_CHILD_SEARCH_SUCCESS',
    FETCH_CHILD_PROJECT_SUCCESS:"FETCH_CHILD_PROJECT_SUCCESS",
    FETCH_REPORT_PROJECT_SUCCESS:"FETCH_REPORT_PROJECT_SUCCESS",
    FETCH_SINGLE_PROJECT_SUCCESS: 'FETCH_SINGLE_PROJECT_SUCCESS',
    FETCH_SINGLE_EXCLUDE_PROJECT_SUCCESS: 'FETCH_SINGLE_EXCLUDE_PROJECT_SUCCESS',
    ADD_PROJECT_SUCCESS: 'ADD_PROJECT_SUCCESS',
    ADD_CHILD_PROJECT_SUCCESS:"ADD_CHILD_PROJECT_SUCCESS",
    UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
    PROJECT_FAILURE: 'PROJECT_FAILURE',
    DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
    PROJECT_PRESCREEN_SUCCESS: "PROJECT_PRESCREEN_SUCCESS",
    UPDATE_CHILD_PROJECT_SUCCESS:"UPDATE_CHILD_PROJECT_SUCCESS",
    PROJECT_RESET:"PROJECT_RESET"
}

export const invoiceActionTypes = {
    INVOICE_REQUEST: 'INVOICE_REQUEST',
    FETCH_INVOICE_ALL_SUCCESS:'FETCH_INVOICE_ALL_SUCCESS',
    FETCH_INVOICE_SUCCESS: 'FETCH_INVOICE_SUCCESS',
    FETCH_INVOICE_SUPPLIER_SUCCESS: 'FETCH_INVOICE_SUPPLIER_SUCCESS',
    FETCH_SINGLE_INVOICE_SUCCESS: 'FETCH_SINGLE_INVOICE_SUCCESS',
    ADD_INVOICE_SUCCESS: 'ADD_INVOICE_SUCCESS',
    UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',
    INVOICE_FAILURE: 'INVOICE_FAILURE',
    DELETE_INVOICE_SUCCESS: "DELETE_INVOICE_SUCCESS"
}

export const categoryActionTypes = {
    CATEGORY_REQUEST: 'CATEGORY_REQUEST',
    FETCH_CATEGORY_ALL_SUCCESS:'FETCH_CATEGORY_ALL_SUCCESS',
    FETCH_CATEGORY_SUCCESS: 'FETCH_CATEGORY_SUCCESS',
    FETCH_SINGLE_CATEGORY_SUCCESS: 'FETCH_SINGLE_CATEGORY_SUCCESS',
    ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    CATEGORY_FAILURE: 'CATEGORY_FAILURE',
    DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS"
}

export const supplierRuleActionTypes = {
    SUPPLIER_RULE_REQUEST: 'SUPPLIER_RULE_REQUEST',
    FETCH_SUPPLIER_RULE_ALL_SUCCESS:'FETCH_SUPPLIER_RULE_ALL_SUCCESS',
    FETCH_SUPPLIER_RULE_SUCCESS: 'FETCH_SUPPLIER_RULE_SUCCESS',
    FETCH_SINGLE_SUPPLIER_RULE_SUCCESS: 'FETCH_SINGLE_SUPPLIER_RULE_SUCCESS',
    ADD_SUPPLIER_RULE_SUCCESS: 'ADD_SUPPLIER_RULE_SUCCESS',
    UPDATE_SUPPLIER_RULE_SUCCESS: 'UPDATE_SUPPLIER_RULE_SUCCESS',
    SUPPLIER_RULE_FAILURE: 'SUPPLIER_RULE_FAILURE',
    DELETE_SUPPLIER_RULE_SUCCESS: "DELETE_SUPPLIER_RULE_SUCCESS"
}


export const clientActionTypes = {
    CLIENT_REQUEST: 'CLIENT_REQUEST',
    FETCH_CLIENT_ALL_SUCCESS:'FETCH_CLIENT_ALL_SUCCESS',
    FETCH_CLIENT_SUCCESS: 'FETCH_CLIENT_SUCCESS',
    FETCH_SINGLE_CLIENT_SUCCESS: 'FETCH_SINGLE_CLIENT_SUCCESS',
    ADD_CLIENT_SUCCESS: 'ADD_CLIENT_SUCCESS',
    UPDATE_CLIENT_SUCCESS: 'UPDATE_CLIENT_SUCCESS',
    CLIENT_FAILURE: 'CLIENT_FAILURE',
    DELETE_CLIENT_SUCCESS: "DELETE_CLIENT_SUCCESS"
}

export const surveyActionTypes = {
    SURVEY_REQUEST: 'SURVEY_REQUEST',
    FETCH_SURVEY_ALL_SUCCESS:'FETCH_SURVEY_ALL_SUCCESS',
    FETCH_SURVEY_SUCCESS: 'FETCH_SURVEY_SUCCESS',
    FETCH_SINGLE_SURVEY_SUCCESS: 'FETCH_SINGLE_SURVEY_SUCCESS',
    ADD_SURVEY_SUCCESS: 'ADD_SURVEY_SUCCESS',
    UPDATE_SURVEY_SUCCESS: 'UPDATE_SURVEY_SUCCESS',
    SURVEY_FAILURE: 'SURVEY_FAILURE',
    DELETE_SURVEY_SUCCESS: "DELETE_SURVEY_SUCCESS",
}

export const questionActionTypes ={
    QUESTION_REQUEST: 'QUESTION_REQUEST',
    FETCH_QUESTION_ALL_SUCCESS:'FETCH_QUESTION_ALL_SUCCESS',
    FETCH_POST_QUESTION_ALL_SUCCESS:'FETCH_POST_QUESTION_ALL_SUCCESS',
    FETCH_QUESTION_SUCCESS: 'FETCH_QUESTION_SUCCESS',
    FETCH_CATEGORY_QUESTION_SUCCESS: 'FETCH_CATEGORY_QUESTION_SUCCESS',
    FETCH_SINGLE_QUESTION_SUCCESS: 'FETCH_SINGLE_QUESTION_SUCCESS',
    ADD_QUESTION_SUCCESS: 'ADD_QUESTION_SUCCESS',
    UPDATE_QUESTION_SUCCESS: 'UPDATE_QUESTION_SUCCESS',
    QUESTION_FAILURE: 'QUESTION_FAILURE',
    DELETE_QUESTION_SUCCESS: "DELETE_QUESTION_SUCCESS",
}

export const qualificationActionTypes ={
    QUALIFICATION_REQUEST: 'QUALIFICATION_REQUEST',
    FETCH_QUALIFICATION_ALL_SUCCESS:'FETCH_QUALIFICATION_ALL_SUCCESS',
    FETCH_QUALIFICATION_SUCCESS: 'FETCH_QUALIFICATION_SUCCESS',
    FETCH_SINGLE_QUALIFICATION_SUCCESS: 'FETCH_SINGLE_QUALIFICATION_SUCCESS',
    ADD_QUALIFICATION_SUCCESS: 'ADD_QUALIFICATION_SUCCESS',
    UPDATE_QUALIFICATION_SUCCESS: 'UPDATE_QUALIFICATION_SUCCESS',
    QUALIFICATION_FAILURE: 'QUALIFICATION_FAILURE',
    DELETE_QUALIFICATION_SUCCESS: "DELETE_QUALIFICATION_SUCCESS",
}

export const quotaActionTypes ={
    QUOTA_REQUEST: 'QUOTA_REQUEST',
    FETCH_QUOTA_ALL_SUCCESS:'FETCH_QUOTA_ALL_SUCCESS',
    FETCH_QUOTA_SUCCESS: 'FETCH_QUOTA_SUCCESS',
    FETCH_SINGLE_QUOTA_SUCCESS: 'FETCH_SINGLE_QUOTA_SUCCESS',
    ADD_QUOTA_SUCCESS: 'ADD_QUOTA_SUCCESS',
    UPDATE_QUOTA_SUCCESS: 'UPDATE_QUOTA_SUCCESS',
    QUOTA_FAILURE: 'QUOTA_FAILURE',
    DELETE_QUOTA_SUCCESS: "DELETE_QUOTA_SUCCESS",
}

export const supplierActionTypes = {
    SUPPLIER_REQUEST: 'SUPPLIER_REQUEST',
    FETCH_SUPPLIER_ALL_SUCCESS:'FETCH_SUPPLIER_ALL_SUCCESS',
    FETCH_SUPPLIER_SUCCESS: 'FETCH_SUPPLIER_SUCCESS',
    FETCH_SINGLE_SUPPLIER_SUCCESS: 'FETCH_SINGLE_SUPPLIER_SUCCESS',
    ADD_SUPPLIER_SUCCESS: 'ADD_SUPPLIER_SUCCESS',
    UPDATE_SUPPLIER_SUCCESS: 'UPDATE_SUPPLIER_SUCCESS',
    SUPPLIER_FAILURE: 'SUPPLIER_FAILURE',
    DELETE_SUPPLIER_SUCCESS: "DELETE_SUPPLIER_SUCCESS"
}

export const supplierMapActionTypes = {
    SUPPLIER_MAP_REQUEST: 'SUPPLIER_MAP_REQUEST',
    FETCH_SUPPLIER_MAP_ALL_SUCCESS:'FETCH_SUPPLIER_MAP_ALL_SUCCESS',
    FETCH_SUPPLIER_MAP_SUCCESS: 'FETCH_SUPPLIER_MAP_SUCCESS',
    FETCH_SINGLE_SUPPLIER_MAP_SUCCESS: 'FETCH_SINGLE_MAP_SUPPLIER_SUCCESS',
    ADD_SUPPLIER_MAP_SUCCESS: 'ADD_SUPPLIER_MAP_SUCCESS',
    UPDATE_SUPPLIER_MAP_SUCCESS: 'UPDATE_SUPPLIER_MAP_SUCCESS',
    SUPPLIER_MAP_FAILURE: 'SUPPLIER_MAP_FAILURE',
    DELETE_SUPPLIER_MAP_SUCCESS: "DELETE_SUPPLIER_MAP_SUCCESS",
    SUPPLIER_MAP_UPDATE_CANCEL_SUCCESS:'SUPPLIER_MAP_UPDATE_CANCEL_SUCCESS'
}



export const sectionActionTypes = {
    SECTION_REQUEST: 'SECTION_REQUEST',
    FETCH_SECTION_SUCCESS: 'FETCH_SECTION_SUCCESS',
    ADD_SECTION_SUCCESS: 'ADD_SECTION_SUCCESS',
    DELETE_SECTION_SUCCESS:'DELETE_SECTION_SUCCESS',
    SECTION_FAILURE: 'SECTION_FAILURE',
}
export const analyticsActionTypes = {
    ANYLTICS_REQUEST: 'ANYLTICS_REQUEST',
    FETCH_ANYLTICS_SUCCESS: 'FETCH_ANYLTICS_SUCCESS',
    ANYLTICS_FAILURE: 'ANYLTICS_FAILURE',
}

export const pageActionTypes = {
    PAGE_REQUEST: 'PAGE_REQUEST',
    FETCH_PAGE_SUCCESS: 'FETCH_PAGE_SUCCESS',
    UPDATE_USER_PAGE_SUCCESS:"UPDATE_USER_PAGE_SUCCESS",
    UPDATE_FIELD_PAGE_SUCCESS:"UPDATE_FIELD_PAGE_SUCCESS",
    UPDATE_PAGE_SUCCESS: 'UPDATE_PAGE_SUCCESS',
    PAGE_FAILURE: 'PAGE_FAILURE',
}

export const cardActionTypes = {
    CARD_REQUEST: 'CARD_REQUEST',
    FETCH_CARD_SUCCESS: 'FETCH_CARD_SUCCESS',
    CARD_FAILURE: 'CARD_FAILURE',
}


export const countryActionTypes = {
    COUNTRY_REQUEST: 'COUNTRY_REQUEST',
    FETCH_COUNTRY_SUCCESS: 'FETCH_COUNTRY_SUCCESS',
    FETCH_REGION_SUCCESS: 'FETCH_REGION_SUCCESS',
    FETCH_CITY_SUCCESS: 'FETCH_CITY_SUCCESS',
    FETCH_CURRENCY_SUCCESS: 'FETCH_CURRENCY_SUCCESS',
    FETCH_LANGUAGE_SUCCESS: 'FETCH_LANGUAGE_SUCCESS',
    FETCH_SINGLE_COUNTRY_SUCCESS: 'FETCH_SINGLE_COUNTRY_SUCCESS',
    ADD_COUNTRY_SUCCESS: 'ADD_COUNTRY_SUCCESS',
    UPDATE_COUNTRY_SUCCESS: 'UPDATE_COUNTRY_SUCCESS',
    COUNTRY_FAILURE: 'COUNTRY_FAILURE',
    DELETE_COUNTRY_SUCCESS: "DELETE_COUNTRY_SUCCESS"
}



export const staticRedirectActionTypes = {
    STATIC_REDIRECT_REQUEST: 'STATIC_REDIRECT_REQUEST',
    FETCH_STATIC_REDIRECT_SUCCESS: 'FETCH_STATIC_REDIRECT_SUCCESS',
    STATIC_REDIRECT_FAILURE: 'STATIC_REDIRECT_FAILURE',
}



export const shortCodeActionTypes = {
    SHORTCODE_REQUEST: 'SHORTCODE_REQUEST',
    FETCH_SHORTCODE_SUCCESS: 'FETCH_SHORTCODE_SUCCESS',
    SHORTCODE_FAILURE: 'SHORTCODE_FAILURE',
}


export const systemLogActionTypes = {
    SYSTEM_LOG_REQUEST: 'SYSTEM_LOG_REQUEST',
    FETCH_SYSTEM_LOG_SUCCESS: 'FETCH_SYSTEM_LOG_SUCCESS',
    SYSTEM_LOG_FAILURE: 'SYSTEM_LOG_FAILURE',
}


export const messageActionTypes = {
    MESSAGE_REQUEST: 'MESSAGE_REQUEST',
    FETCH_MESSAGE_SUCCESS: 'FETCH_MESSAGE_SUCCESS',
    FETCH_MESSAGE_ALL_SUCCESS:"FETCH_MESSAGE_ALL_SUCCESS",
    ADD_MESSAGE_SUCCESS:'ADD_MESSAGE_SUCCESS',
    FETCH_SINGLE_MESSAGE_SUCCESS:'FETCH_SINGLE_MESSAGE_SUCCESS',
    UPDATE_MESSAGE_SUCCESS:'UPDATE_MESSAGE_SUCCESS',
    DELETE_MESSAGE_SUCCESS:'DELETE_MESSAGE_SUCCESS',
    MESSAGE_FAILURE: 'MESSAGE_FAILURE',
}

export const scheduleJobActionTypes = {
    SCHEDULE_JOB_REQUEST: 'SCHEDULE_JOB_REQUEST',
    FETCH_SCHEDULE_JOB_SUCCESS: 'FETCH_SCHEDULE_JOB_SUCCESS',
    SCHEDULE_JOB_DELETE_SUCCESS:'SCHEDULE_JOB_DELETE_SUCCESS',
    SCHEDULE_JOB_ADD_SUCCESS: 'SCHEDULE_JOB_ADD_SUCCESS',
    SCHEDULE_JOB_UPDATE_SUCCESS: 'SCHEDULE_JOB_UPDATE_SUCCESS',
    SCHEDULE_JOB_FAILURE: 'SCHEDULE_JOB_FAILURE',
}


export const tabActionTypes = {
    QUESTIONS_TAB: 'QUESTIONS_TAB',
    EDIT_QUESTION_TAB: 'EDIT_QUESTION_TAB',
}

export const emailActionTypes = {
    EMAIL_REQUEST: 'EMAIL_REQUEST',
    EMAIL_SUCCESS: 'EMAIL_SUCCESS',
    EMAIL_FAILURE: 'EMAIL_FAILURE',
}



