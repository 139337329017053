import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';
import { CompanyAddRequest } from 'src/actions/companyActions';
import TopBarForm from 'src/components/shared/TopBarForm';
import CompanyForm from 'src/components/admin/_dashboard/company/CompanyForm';
import { Company } from 'src/types/companyTypes';

export default function AddCompany() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const initialValues: any = null;

    const submitForm = async (values: Company, setSubmitting: any) => {
        setTimeout(() => dispatch(CompanyAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Add Company"  link="/admin/company"/>
            <CompanyForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
