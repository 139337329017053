import { useContext } from 'react';
import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import MessageContext from 'src/context/MessageContext';
import TopBarForm from 'src/components/shared/TopBarForm';
import { adminInfoProps } from 'src/types/adminTypes';
import CompanyForm from 'src/components/admin/_dashboard/company/CompanyForm';
import { CompanySingleRequest, CompanyUpdateRequest } from 'src/actions/companyActions';

export default function EditCompany() {

    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const { companyid }: any = useParams<any>();
    const submitForm = async (values: adminInfoProps, setSubmitting: any) => {
        setTimeout(() => dispatch(CompanyUpdateRequest(companyid, values, setMessageHandler, setSubmitting)), 200);
    }
    const { company } = useSelector(state => state.company);
    var initialValues: adminInfoProps = company;

    useEffect(() => {
        dispatch(CompanySingleRequest(companyid));
    }, [companyid])

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Edit Company" link="/admin/company" />
            <CompanyForm submitForm={submitForm} initialValues={initialValues} />
        </Container>
    );
}
