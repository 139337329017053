import { companyActionTypes } from "src/types/actionTypes";
import { adminInfoProps } from "src/types/adminTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import {  CompanyList } from "src/types/companyTypes";

export type companyAction = SuccessAction<adminInfoProps> | SuccessAction<adminInfoProps> | SuccessAction<any> | ErrorAction
export const defaultCompanyState: CompanyList = { loading:true, redirectTo:'',companies:[], company :{}, data: [], metadata: {} };

const reducer = (state: CompanyList = defaultCompanyState, action: companyAction): CompanyList => {
    switch (action.type) {
        case companyActionTypes.COMPANY_REQUEST:
            state = { ...state, loading:true }
            return state
        case companyActionTypes.FETCH_COMPANY_SUCCESS:
            action = <SuccessAction<CompanyList>>action
            state = { ...state,redirectTo:'', data: action.payload.data, metadata: action.payload.metadata ,loading:false }
            return state
            case companyActionTypes.FETCH_COMPANY_SUCCESS_ALL:
            action = <SuccessAction<CompanyList>>action
            state = { ...state, companies: action.payload, loading:false }
            return state
        case companyActionTypes.UPDATE_COMPANY_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,redirectTo:'/admin/company' ,data: state.data?.map(dat => [action.payload].find(o => o._id == dat._id) || dat) }
            return state
        case companyActionTypes.FETCH_SINGLE_COMPANY_SUCCESS:
            action = <SuccessAction<adminInfoProps>>action
            state = { ...state, company:  action.payload }
            return state
        case companyActionTypes.DELETE_COMPANY_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, data: state.data?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case companyActionTypes.ADD_COMPANY_SUCCESS:
            action = <SuccessAction<adminInfoProps>>action
            state = { ...state,redirectTo:'/admin/company', data: state.data?.concat(action.payload) }
            return state
        case companyActionTypes.COMPANY_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}
export default reducer