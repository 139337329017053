import { useEffect } from 'react';
import { useState } from 'react';
import { Container, IconButton, Typography, Tooltip, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { DataGridRender } from 'src/components/table';
import { sentenceCase } from 'change-case';
import Label from 'src/components/Label';
import { ProjectReportsRequest, ProjectResetSuccess, ProjectGroupExportRequest, ProjectPrescreenReportsRequest, SupplierDataExportRequest, ProjectInvoiceExportRequest, ProjectIdentifierExportRequest, ProjectClientCustomExportRequest } from 'src/actions/projectActions';
import { Download } from '@mui/icons-material';
import TabLayout from 'src/components/tab/TabLayout';
import TopBar from 'src/components/shared/TopBar';
import { ProjectStatus } from 'src/constants';
import { projectActionTypes } from 'src/types/actionTypes';

export default function ProjectReport() {

  const [tabValue, setTabValue] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<any>({});
  const [filterTypes, setFilterTypes] = useState<any>(['dateRange', 'export', 'client', 'download'])
  const dispatch = useDispatch();
  const { project, reportProjects } = useSelector(state => state.project);

  useEffect(() => {
    if (tabValue == 5) {
      dispatch(ProjectReportsRequest(filters, { status: 'c' }));
    }
    if (tabValue == 7) {
      return;
    }
    if (tabValue == 8) {
      return;
    }
    
    else if (Object.keys(filters).length) {
      dispatch(ProjectReportsRequest(filters, {}));
    }
    else {
      dispatch(ProjectResetSuccess({}));
    }
  }, [filters, tabValue]);


  const exportAction = (id: string, projectName: string) => {
    if (tabValue == 1) dispatch(ProjectGroupExportRequest({ projectId: id }, projectName));
    if (tabValue == 2) dispatch(ProjectGroupExportRequest({ projectId: id }, projectName));
    if (tabValue == 3) dispatch(ProjectPrescreenReportsRequest({ projectId: id }, projectName));
  }

  const exportSupplierAction = (supplierName: string) => {
    dispatch(SupplierDataExportRequest(filters, supplierName));
  }

  const columns = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 150, pinnable: true, renderCell: ({ id, value }: any) => {
        return <Typography color="primary" >{value}</Typography>
      }
    },
    {
      field: 'projectName', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left',  flex: 1, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    { field: 'complete', type: "string", headerName: 'Complete', filterable: false, headerAlign: "center", align: 'center', width: 100 },
    {
      field: 'approved', type: "string", headerName: 'Approved', filterable: false, width: 100, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['approved']}/${row['supplierApproved']}`;
      },
    },
    { field: 'terminate', type: "string", headerName: 'ST', filterable: false, headerAlign: "center", align: 'center',width: 80 },
    { field: 'qualityTerm', type: "string", headerName: 'QT', filterable: false, headerAlign: "center", align: 'center', width: 80 },
    { field: 'overQuota', type: "string", headerName: 'OQ', filterable: false, headerAlign: "center", align: 'center',width: 80},
    { field: 'clicked', type: "string", headerName: 'Traffic', filterable: false, headerAlign: "center", align: 'center',width: 100 },
    { field: 'totalDisqualified', type: "string", headerName: 'Disqualified', filterable: false, headerAlign: "center", align: 'center', width: 120 },
    { field: 'drop', type: "string", headerName: 'DO', filterable: false, headerAlign: "center", align: 'center', width: 120 },
    {
      field: 'projectStatus', headerName: 'Status', type: "number", width: 80, align: 'center',
      renderCell: (params: any) => {
        var status: any = ProjectStatus.find(status => status.value == params.value);
        return <>
            <Label
              variant="ghost"
              color={status?.color}
            >
              {sentenceCase(status.label)}
            </Label>

        </>
      }
    },
    {
      field: 'isActive', headerName: '', headerAlign: "center", align: 'center', disableExport: true, type: "boolean", width: 80, renderCell: ({ row }: any) => {
        return <IconButton onClick={() => exportAction(row._id, row['projectCode'] + "_" + row['projectName'])}>
          <Download />
        </IconButton>
      }
    },
  ];

  const columnsComplete = [
    { field: 'complete', type: "string", headerName: 'Total Completes', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    { field: 'approved', type: "string", headerName: 'Total Approved', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    {
      field: 'isActive', headerName: 'Action', headerAlign: "center", align: 'center', disableExport: true, type: "boolean", width: 200, renderCell: ({ row }: any) => {
        return <IconButton onClick={() => exportSupplierAction(row['supplierName'])}>
          <Download />
        </IconButton>
      }
    },
  ];


  const columnsInvoice = [
    { field: 'invoices', type: "string", headerName: 'Total Invoices', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    {
      field: 'isActive', headerName: 'Action', headerAlign: "center", align: 'center', disableExport: true, type: "boolean", flex: 1, renderCell: ({ row }: any) => {
        return <IconButton onClick={() => exportAction(row._id, row['projectCode'] + "_" + row['projectName'])}>
          <Download />
        </IconButton>
      }
    },
  ];

  const handleChange = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
    setFilters({});
    dispatch({ type: projectActionTypes.FETCH_REPORT_PROJECT_SUCCESS, payload: { reportProjects: [], loading: false } });
    if (value == 1) setFilterTypes(['dateRange', 'export', 'client']);
    if (value == 2) setFilterTypes(['dateRange', 'export', 'supplier']);
    if (value == 3) setFilterTypes(['dateRange', 'export', 'project']);
    if (value == 4) setFilterTypes(['dateRange', 'export']);
    if (value == 5) setFilterTypes(['dateRange', 'export', 'supplier']);
    if (value == 6) setFilterTypes(['searcharea', 'supplier', 'exportIdentifier']);
    if (value == 7) setFilterTypes(['projectRange', 'export', 'client','exportIdentifier','invoiceStatus']);
    if (value == 8) setFilterTypes(['dateRange', 'export', 'client','exportIdentifier']);
  }

  const exportIdentfier = () => {
    dispatch(ProjectIdentifierExportRequest({ projectData: filters.search, supplier: filters.supplier }, setLoading));
  }

  const exportInvoice = () => {
    dispatch(ProjectInvoiceExportRequest({ projectFrom: filters.projectFrom,projectTo: filters.projectTo,invoiceStatus: filters.invoiceStatus,client: filters.client }, setLoading));
  }

  const exportClientProject = () => {
    dispatch(ProjectClientCustomExportRequest({filter: filters }, setLoading));
  }

  
  return (
    <Container maxWidth={false}>
      <TopBar title="Reports" link="" buttonText="" />
      <TabLayout handleChange={handleChange} tabValue={tabValue}>
        <Tab label="Client" value={1} />
        <Tab label="Supplier" value={2} />
        <Tab label="Prescreen" value={3} />
        <Tab label="Complete Allocated" value={4} />
        <Tab label="Complete" value={5} />
        <Tab label="Project Complete" value={6} />
        <Tab label="Invoice" value={7} />
        <Tab label="Custom Client" value={8} />
      </TabLayout>
      {tabValue == 1 &&
        <DataGridRender
          filterTypes={filterTypes}
          data={reportProjects}
          metadata=""
          limit={limit}
          filters={filters}
          pageNo={pageNo}
          exportFileName='data'
          columns={columns}
          setPageNo={setPageNo}
          exportIdentifierLoading={loading}
          exportIdentfier={exportIdentfier}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />}

      {tabValue == 2 &&
        <DataGridRender
          filterTypes={filterTypes}
          data={reportProjects}
          metadata=""
          limit={limit}
          filters={filters}
          pageNo={pageNo}
          exportFileName='data'
          columns={columns}
          setPageNo={setPageNo}
          exportIdentifierLoading={loading}
          exportIdentfier={exportIdentfier}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />}
      {tabValue == 3 &&
        <DataGridRender
          filterTypes={filterTypes}
          data={reportProjects}
          metadata=""
          limit={limit}
          filters={filters}
          pageNo={pageNo}
          exportFileName='data'
          columns={columns}
          setPageNo={setPageNo}
          exportIdentifierLoading={loading}
          exportIdentfier={exportIdentfier}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />}
      {tabValue == 4 &&
        <DataGridRender
          filterTypes={filterTypes}
          data={reportProjects}
          metadata=""
          limit={limit}
          filters={filters}
          pageNo={pageNo}
          exportFileName='data'
          columns={columns}
          setPageNo={setPageNo}
          exportIdentifierLoading={loading}
          exportIdentfier={exportIdentfier}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />}
      {tabValue == 6 &&
        <DataGridRender
          filterTypes={filterTypes}
          data={reportProjects}
          metadata=""
          limit={limit}
          filters={filters}
          pageNo={pageNo}
          exportFileName='data'
          columns={columns}
          setPageNo={setPageNo}
          exportIdentifierLoading={loading}
          exportIdentfier={exportIdentfier}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />}

      {tabValue == 5 &&
        <DataGridRender
          filterTypes={filterTypes}
          data={reportProjects}
          metadata=""
          limit={limit}
          filters={filters}
          pageNo={pageNo}
          exportFileName={project.projectCode + "_" + project.projectName}
          columns={columnsComplete}
          setPageNo={setPageNo}
          exportIdentfier={exportIdentfier}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />}
      {tabValue == 7 &&
      
        <DataGridRender
          filterTypes={filterTypes}
          data={reportProjects}
          metadata=""
          limit={limit}
          filters={filters}
          pageNo={pageNo}
          exportFileName='data'
          columns={columnsInvoice}
          setPageNo={setPageNo}
          exportIdentifierLoading={loading}
          exportIdentfier={exportInvoice}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />}

{tabValue == 8 &&
      
      <DataGridRender
        filterTypes={filterTypes}
        data={reportProjects}
        metadata=""
        limit={limit}
        filters={filters}
        pageNo={pageNo}
        exportFileName='data'
        columns={columnsInvoice}
        setPageNo={setPageNo}
        exportIdentifierLoading={loading}
        exportIdentfier={exportClientProject}
        setLimit={setLimit}
        setFilters={setFilters}
        hideFooterPagination={true}
      />}

    </Container>
  );
}
